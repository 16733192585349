
<template>
  <div class="container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" type="card">
      <el-tab-pane :label="item.name" :name="item.id" v-for="(item, index) in data_list" :key="index"></el-tab-pane>

    </el-tabs>

    <el-card>
      <div class="search">
        <el-row>
          <el-col :span="6">
            <span>风险名称 : </span>
            <!-- <el-input
              v-model="riskname"
              placeholder="请输入名称"
            ></el-input> -->
            <el-select style="width: 80%" v-model="riskname" placeholder="请选择">
              <el-option v-for="item in tableData1s" :key="item.value" :label="item.text" :value="item.value">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="5">
            <span>风险等级 : </span>
            <el-select v-model="riskrank" placeholder="请选择">
              <el-option v-for="item in Risklevel" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <!-- <el-button type="info" @click="searchRisk" plain>搜索</el-button> -->
            <el-button type="primary" icon="el-icon-search" @click="searchRisk" plain>搜索</el-button>
            <el-button type="warning" @click="reset" plain>重置</el-button>
            <!-- <el-button type="primary" @click="openDialog">添加风险</el-button> -->
            <el-dialog title="从风险库导入模板数据" :visible.sync="table_tk" width="60%">
              分部分项名称：
              <el-select v-model="data_xialakuang_one" placeholder="请选择" style="padding-right: 40px">
                <el-option v-for="item in data_xialakuang_list" :key="item.value" :label="item.label" :value="item.label">
                </el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-search" @click="sousuo_name">搜索</el-button>
              <el-scrollbar style="height:400px">


                <el-table :data="tableDatas" border style="width: 100%; margin-top: 30px;"
                  @selection-change="handleSelectionChanges">
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column prop="name" label="分部分项工程名称/作业任务" width="180">
                  </el-table-column>
                  <el-table-column prop="risk_name" label="作业活动名称" width="120">
                  </el-table-column>
                  <el-table-column prop="name_centen" label="作业活动内容" width="120">
                  </el-table-column>
                  <el-table-column prop="hazard" label="主要有害因素（人、物、环、管）">
                  </el-table-column>
                  <el-table-column label="风险评价">
                    <el-table-column prop="riskrankss.L" label="L" width="35">
                    </el-table-column>
                    <el-table-column prop="riskrankss.E" label="E" width="40">
                    </el-table-column>
                    <el-table-column prop="riskrankss.C" label="C" width="40">
                    </el-table-column>
                    <el-table-column prop="num" label="D" width="50">
                    </el-table-column>
                  </el-table-column>

                  <el-table-column prop="riskrank" label="风险等级" width="120">
                  </el-table-column>
                  <el-table-column prop="charge" label="管控层级" width="120">
                  </el-table-column>
                </el-table>
              </el-scrollbar>
              <!-- <el-pagination
                @size-change="table_handleSizeChange"
                @current-change="table_handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[5, 10, 15, 20, 1000]"
                :page-size="5"
                layout="total, sizes, prev, pager, next, jumper"
                :total="table_count"
              >
              </el-pagination> -->
              <span slot="footer" class="dialog-footer">
                <el-button @click="table_tk = false">取 消</el-button>
                <el-button type="primary" @click="table_add_risk">确 定</el-button>
              </span>
            </el-dialog>
            <el-dialog title="从风险库导入模板数据" :visible.sync="table_tks" width="60%">
              分部分项名称：
              <el-select v-model="data_xialakuang_two" placeholder="请选择" style="padding-right: 40px">
                <el-option v-for="item in data_two_xialakuang_list" :key="item.value" :label="item.label"
                  :value="item.label">
                </el-option>
              </el-select>
              <el-button type="primary" icon="el-icon-search" @click="sousuo_name_two">搜索</el-button>
              <el-scrollbar style="height:400px">
                <el-table :data="tableDatas" border style="width: 100%" @selection-change="handleSelectionChanges">
                  <el-table-column type="selection" width="55"> </el-table-column>
                  <el-table-column prop="name" label="分部分项工程名称/作业任务" width="180">
                  </el-table-column>
                  <el-table-column prop="risk_name" label="作业活动名称" width="120">
                  </el-table-column>
                  <el-table-column prop="name_centen" label="作业活动内容" width="120">
                  </el-table-column>
                  <el-table-column prop="hazard" label="主要有害因素（人、物、环、管）">
                  </el-table-column>
                  <el-table-column label="风险评价">
                    <el-table-column prop="riskrankss.K" label="L" width="35">
                    </el-table-column>
                    <el-table-column prop="riskrankss.Y" label="E" width="40">
                    </el-table-column>
                  </el-table-column>
                  <el-table-column prop="riskrank" label="风险等级" width="120">
                  </el-table-column>
                  <el-table-column prop="charge" label="管控层级" width="120">
                  </el-table-column>
                </el-table>
              </el-scrollbar>
              <!-- <el-pagination
                @size-change="table_handleSizeChanges"
                @current-change="table_handleCurrentChanges"
                :current-page="currentPage4"
                :page-sizes="[5, 10, 15, 20, 1000]"
                :page-size="5"
                layout="total, sizes, prev, pager, next, jumper"
                :total="table_count"
              >
              </el-pagination> -->
              <span slot="footer" class="dialog-footer">
                <el-button @click="table_tks = false">取 消</el-button>
                <el-button type="primary" @click="table_add_risks">确 定</el-button>
              </span>
            </el-dialog>

            <el-dialog title="选择风险类别" :visible.sync="table_all_addl" width="30%">
              <el-button type="primary" @click="table_all" plain round>作业活动风险</el-button>
              <el-button type="primary" @click="table_alls" plain round>设备设施风险</el-button>
              <el-button type="primary" @click="dialogFormVisible = true" plain round>自定义风险</el-button>
            </el-dialog>

            <!-- //自定义弹框 -->
            <el-dialog title="自定义风险" :visible.sync="dialogFormVisible" width="50%">
              <el-form :model="form">
                <div class="bbb">
                  <div class="left">
                    <el-form-item label="名称" :label-width="formLabelWidth">
                      <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="风险名称" :label-width="formLabelWidth">
                      <el-input v-model="form.riskname"></el-input>
                    </el-form-item>
                    <el-form-item label="工作内容" :label-width="formLabelWidth">
                      <el-input v-model="form.risktype"></el-input>
                    </el-form-item>
                    <el-form-item label="潜在事故类型" :label-width="formLabelWidth">
                      <el-input v-model="form.riskpoint"></el-input>
                    </el-form-item>
                    <el-form-item label="危害因素" :label-width="formLabelWidth">
                      <el-input v-model="form.hazard"></el-input>
                    </el-form-item>
                    <el-form-item label="管理措施" :label-width="formLabelWidth">
                      <el-input v-model="form.controls"></el-input>
                    </el-form-item>
                    <el-form-item label="工程措施" :label-width="formLabelWidth">
                      <el-input v-model="form.projectrola" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="个人措施" :label-width="formLabelWidth">
                      <el-input v-model="form.personage" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                  <div class="right">
                    <el-form-item label="应急措施" :label-width="formLabelWidth">
                      <el-input v-model="form.manager" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="风险程度" :label-width="formLabelWidth">
                      <el-input v-model="form.riskrank" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="管控等级" :label-width="formLabelWidth">
                      <el-input v-model="form.control" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="作业人员" :label-width="formLabelWidth">
                      <el-input v-model="form.control1" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="班组人员" :label-width="formLabelWidth">
                      <el-input v-model="form.control2" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="项目负责人" :label-width="formLabelWidth">
                      <el-input v-model="form.control3" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="企业/公司负责人" :label-width="formLabelWidth">
                      <el-input v-model="form.control4" autocomplete="off"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-form-item label="事故发生可能性" v-show="show" :label-width="formLabelWidth">
                  <el-input v-model="form.K" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发生事故后果可能性" v-show="show" :label-width="formLabelWidth">
                  <el-input v-model="form.Y" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="事故发生可能性" v-show="show1" :label-width="formLabelWidth">
                  <el-input v-model="form.L" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="人员暴露频繁程度" v-show="show1" :label-width="formLabelWidth">
                  <el-input v-model="form.E" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="发生事故后果可能性" v-show="show1" :label-width="formLabelWidth">
                  <el-input v-model="form.C" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="风险类型" :label-width="formLabelWidth">
                  <el-select style="width: 100%" v-model="value" placeholder="请选择" @change="dropDown">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-checkbox-group v-model="checkList" @change="multiSelect">
                    <el-checkbox label="班中巡查"></el-checkbox>
                    <el-checkbox label="班中交接班"></el-checkbox>
                    <el-checkbox label="每半年/企业"></el-checkbox>
                    <el-checkbox label="每周/项目部"></el-checkbox>
                    <el-checkbox label="每月/企业"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="bounced">确 定</el-button>
              </div>
            </el-dialog>

            <el-button type="danger" @click="deleteBatches" style="margin-left: 5px" plain>批量删除</el-button>
            <!-- 批量删除弹框 -->
            <el-dialog title="需要删除的数据" :visible.sync="outerVisible">
              <el-dialog width="30%" title="确认删除" :visible.sync="innerVisible" append-to-body>
                确认删除{{ duoxuqan.length }}条数据
                <span slot="footer" class="dialog-footer">
                  <el-button @click="innerVisible = false">取 消</el-button>
                  <el-button type="primary" @click="confirmdeletion
                    ">确定</el-button>
                </span>
              </el-dialog>

              <el-table :data="duoxuqan" style="width: 100%">
                <el-table-column prop="corporation" label="项目名称" width="180">
                </el-table-column>
                <el-table-column prop="jkgc" label="工程名称" width="180">
                </el-table-column>
                <el-table-column prop="riskname" label="作业活动名称">
                </el-table-column>
                <el-table-column prop="risktype" label="作业活动内容">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="danger" @click="handleDelete(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="primary" @click="innerVisible = true">确认</el-button>
              </div>
            </el-dialog>
            <!-- ----------------------- -->
            <el-button type="success" style="margin-left: 5px" @click="table_all_addl = true" plain>添加风险</el-button>

            <el-button type="success" @click="openControls()" plain>分级管控</el-button>
          </el-col>
        </el-row>
        <el-alert style="margin-top: 10px" title="提示:分级管控前请选择风险等级并筛选工程名称" type="warning" center show-icon>
        </el-alert>
      </div>
      <div class="main">
        <el-table :data="tableData" style="width:100%;overflow:auto;height:750px" border stripe :cell-style="cellStyle"
          @selection-change="handleSelectionChange" v-infinite-scroll="load">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="corporation" label="项目名称" align="center">
          </el-table-column>
          <el-table-column prop="jkgc" label="工程名称" align="center" :filters="this.con" :filter-method="filterTag">
          </el-table-column>
          <el-table-column prop="riskname" label="作业活动名称" align="center">
          </el-table-column>
          <el-table-column prop="risktype" label="作业活动内容" align="center">
          </el-table-column>

          <el-table-column prop="riskpoint" label="潜在事故类型" align="center">
          </el-table-column>
          <el-table-column prop="riskrank" label="风险等级" align="center">
          </el-table-column>

          <el-table-column prop="details.hazard" label="主要危险因素" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="details.projectrola" label="工程措施" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="details.controls" label="管理措施" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="details.personage" label="个体防护" align="center">
          </el-table-column>
          <el-table-column prop="details.manager" label="应急措施" align="center">
          </el-table-column>
          <el-table-column prop="functionarys" label="分级管控" align="center" style="font-size:5px;" show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- {{ scope.row.functionarys }} -->
              <span v-if="scope.row.functionarys">
                <span v-if="scope.row.functionarys.control1">项目级责任人:{{ scope.row.functionarys.control1 }}</span>
                <span v-if="scope.row.functionarys.control2">班组级责任人:{{ scope.row.functionarys.control2 }}</span>
                <span v-if="scope.row.functionarys.control3">施工人员责任人:{{ scope.row.functionarys.control3 }}</span>
                <span v-if="scope.row.functionarys.control4">企业级责任人:{{ scope.row.functionarys.control4 }}</span>
              </span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="200px">
            <template slot-scope="scope">
              <el-dialog title="修改风险" :visible.sync="xiugaifengxian" width="70%">
                <div class="basicInfo">
                  <div class="basicTitle">
                    <span>基本信息</span>
                  </div>
                  <div class="basicBody">
                    <div class="basicProject">
                      <div>
                        项目名称: <span>{{ riskInfo.corporations }}</span>
                      </div>
                      <div>
                        所属公司: <span>{{ riskInfo.frim_name }}</span>
                      </div>
                      <div>备注:</div>
                    </div>
                    <div v-if="activeName == 1" class="basicProject">
                      <div>
                        作业任务: <span>{{ riskInfo.risktype }}</span>
                      </div>
                      <div>
                        作业活动名称: <span> {{ riskInfo.riskname }}</span>
                      </div>
                      <div>
                        作业活动内容: <span>{{ riskInfo.riskpoint }}</span>
                      </div>
                    </div>
                    <div v-else class="basicProject">
                      <div>
                        设备设施类别: <span>{{ riskInfo.risktype }}</span>
                      </div>
                      <div>
                        设备设施名称: <span> {{ riskInfo.riskname }}</span>
                      </div>
                      <div>
                        设备设施属性: <span>{{ riskInfo.riskpoint }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="riskEvaluation">
                  <div class="basicTitle">
                    <span>风险评价</span>
                  </div>
                  <div class="evaluationBody">
                    <div>
                      风险点名称: <span>{{ riskInfo.riskname }}</span>
                    </div>
                    <div>
                      潜在事故类型: <span> {{ riskInfo.riskpoint }}</span>
                    </div>
                    <div>
                      主要危害因素:
                      <span><input v-model="hazard" placeholder="请输入内容" /></span>
                    </div>
                    <div>
                      工程措施:
                      <span><input v-model="projectrola" placeholder="请输入内容" /></span>
                    </div>
                    <div>
                      管理措施:
                      <span><input v-model="controls" placeholder="请输入内容" /></span>
                    </div>
                    <div>
                      个人措施:
                      <span><input v-model="personage" placeholder="请输入内容" /></span>
                    </div>
                    <div>
                      应急措施:
                      <span><input v-model="manager" placeholder="请输入内容" /></span>
                    </div>
                  </div>
                </div>
                <div v-if="activeName == 1" class="severity">
                  <div class="severityTitle basicTitle">
                    <div>L (事故发生可能性)</div>
                    <div>E (人员暴露频繁程度)</div>
                    <div>C (发生事故后果可能性)</div>
                    <div>风险等级</div>
                  </div>
                  <div class="severityBody">
                    <!-- <div><el-input v-model="riskInfo.L" placeholder="请输入内容" clearable></el-input></div> -->
                    <div>
                      分值 ：{{ L }}
                      <el-select v-model="L" placeholder="请选择">
                        <el-option v-for="item in llist" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div>
                      分值 ：{{ E }}
                      <el-select v-model="E" placeholder="请选择">
                        <el-option v-for="item in llist1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div>
                      分值 ：{{ C }}
                      <el-select v-model="C" placeholder="请选择">
                        <el-option v-for="item in llist2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div>{{ riskInfo.riskrank }}</div>
                  </div>
                </div>
                <div v-else class="severity">
                  <div class="severityTitle basicTitle">
                    <div>L (事故发生可能性)</div>

                    <div>S (发生事故后果可能性)</div>
                    <div>风险等级</div>
                  </div>
                  <div class="severityBody">
                    <div>
                      分值 ：{{ L }}
                      <el-select v-model="L" placeholder="请选择">
                        <el-option v-for="item in llist3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>

                    <div>
                      分值 ：{{ C }}
                      <el-select v-model="C" placeholder="请选择">
                        <el-option v-for="item in llist4" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div>{{ riskInfo.riskrank }}</div>
                  </div>
                </div>
                <div class="controls">
                  <div class="basicTitle">
                    <span>分级管控</span>
                  </div>
                  <div class="controlsBody">
                    <div>
                      负责人:
                      <span v-if="riskInfo.control4">企业级:{{ riskInfo.control4 }}</span>
                      <span v-if="riskInfo.control1">项目级:{{ riskInfo.control1 }}</span>
                      <span v-if="riskInfo.control2">班组级:{{ riskInfo.control2 }}</span>
                      <span v-if="riskInfo.control3">施工人员:{{ riskInfo.control3 }}</span>
                    </div>
                  </div>
                </div>

                <span slot="footer" class="dialog-footer">
                  <el-button @click="xiugaifengxian = false">取 消</el-button>
                  <el-button type="primary" @click="xiugai()">确 定</el-button>
                </span>
              </el-dialog>
              <el-button type="primary" @click="editRisk(scope.row)">修改</el-button>
              <!-- <el-button type="primary" @click="openScreening(scope.row)"
                >隐患排查</el-button
              > -->
              <el-button type="danger" @click="deleteRisk(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- <el-pagination
          @size-change="handleCurrentChange"
          @current-change="handleSizeChange"
          :current-page="page"
          :page-sizes="[5, 10, 20, 100, 500, 1000]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination> -->
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="addDialogVisible" width="50%" class="addForm" @close="closeDialog">
      <el-form ref="addFormData" :model="addFormData" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属项目 : " prop="corporation">
              <el-select v-model="addFormData.corporation" :disabled="!flag" placeholder="请选择">
                <el-option v-for="item in corporationOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属工程 : " prop="engineering">
              <el-select v-model="addFormData.engineering" @change="engineeringHandleChange" placeholder="请选择">
                <el-option v-for="item in engineeringOptions" :key="item.value" :label="item.partial" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作业活动名称 : " prop="workName">
              <el-select v-model="addFormData.workName" @change="risknameHandleChange" placeholder="请选择">
                <el-option v-for="item in workNameOptions" :key="item.id" :label="item.partial" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="作业活动内容 : " prop="pid">
              <el-select v-model="addFormData.pid" placeholder="请选择">
                <el-option v-for="item in workOptions" :key="item.id" :label="item.partial" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRisk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分级管控 -->
    <el-dialog title="分级管控" :visible.sync="controlsdialogVisible" width="30%" @close="closeControlsdialogVisible"
      class="controlsDialog">
      <el-form ref="controlsFormData" :model="controlsFormData" label-width="120px">
        <el-form-item label="企业级责任人 : " v-show="state == '较大风险' || state == '一般风险' || state == '低风险'
          ? false
          : true
          " prop="functionary2">
          <el-input v-model="controlsFormData.functionary4" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="项目级责任人 : " v-show="state == '一般风险' || state == '低风险' ? false : true" prop="functionary">
          <el-input v-model="controlsFormData.functionary1" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="班组级责任人 : " v-show="state == '低风险' ? false : true" prop="functionary2">
          <el-input v-model="controlsFormData.functionary2" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="施工人员责任人 : " prop="functionary3">
          <el-input v-model="controlsFormData.functionary3" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="controlsdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="controlSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 隐患排查 -->
    <el-dialog title="隐患排查" :visible.sync="screeningdialogVisible" width="30%">
      <el-form ref="screeningFormData" :model="screeningFormData" label-width="80px">
        <el-form-item label="排查任务 : ">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="班中巡检"></el-checkbox>
            <el-checkbox label="班中交接班"></el-checkbox>
            <el-checkbox label="每半年/企业"></el-checkbox>
            <el-checkbox label="每周/项目部"></el-checkbox>
            <el-checkbox label="每月/企业"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="screeningdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="screeningSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProjectInfo,
  getThreeRiskInfo,
  update_Risk_data,
} from "../../../request/project";
import {
  addRiskToProject,
  getRiskToProject,
  editRiskToProject,
  deleteRiskToProject,
  showRiskToProject,
  controlToRisk,
  screeningToRisk,
  add_Data_Risk,
  add_table_risk,
  get_name_risk,
  TheCustom,
  batchDeletionRisk,
  personnelInformation
} from "../../../request/risk";
export default {
  data() {
    return {
      data_list: [],
      activeNames: '',
      outerVisible: false,
      innerVisible: false,
      functionaryarr: [],
      state: "",
      optionsrisk: [
        {
          value: "1",
          label: "基坑工程",
        },
        {
          value: "2",
          label: "钢筋工程",
        },
        {
          value: "3",
          label: "模板工程",
        },
        {
          value: "4",
          label: "脚手架工程",
        },
        {
          value: "5",
          label: "砌体工程",
        },
        {
          value: "6",
          label: "施工用电",
        },
        {
          value: "7",
          label: "塔式起重机安拆",
        },
        {
          value: "8",
          label: "施工用电设备",
        },
        {
          value: "9",
          label: "模板直接材料",
        },
        {
          value: "10",
          label: "塔式起重机设备",
        },
        {
          value: "11",
          label: "部分施工机具设备设施",
        },
        {
          value: "12",
          label: "动火作业",
        },
        {
          value: "13",
          label: "施工升降机使用",
        },
        {
          value: "14",
          label: "物料提升机安拆",
        },
        {
          value: "15",
          label: "物料提升机使用",
        },
        {
          value: "16",
          label: "装配式建筑工程",
        },
        {
          value: "17",
          label: "钢结构工程",
        },
        {
          value: "18",
          label: "给排水及采暖工程",
        },
        {
          value: "19",
          label: "混凝土工程",
        },
        {
          value: "20",
          label: "起重设备使用",
        },
        {
          value: "21",
          label: "施工升降机安拆",
        },
        {
          value: "22",
          label: "外挂架作业",
        },
        {
          value: "23",
          label: "其他风险",
        },
      ],
      formLabelWidth: "140px",
      bzxc: 0,
      bzjj: 0,
      bn: 0,
      z: 0,
      Y: 0,
      show1: false,
      show: false,
      siz: "",
      options: [
        {
          value: "1",
          label: "设备设施风险",
        },
        {
          value: "2",
          label: "作业活动风险",
        },
      ],
      value: "",
      dialogFormVisible: false,
      form: {
        name: "",
        riskname: "",
        risktype: "",
        riskpoint: "",
        hazard: "",
        controls: "",
        projectrola: "",
        personage: "",
        manager: "",
        riskrank: "",
        control: "",
        control1: "",
        control2: "",
        control3: "",
        control4: "",
        sb_hd: "",
        L: "",
        E: "",
        C: "",
        K: "",
        Y: "",
      },
      con: [],
      id: 0,
      L: "",
      E: "",
      C: "",
      llist: [
        {
          value: "10",
          label: "完全可以预料",
        },
        {
          value: "6",
          label: "相当可能",
        },
        {
          value: "3",
          label: "可能但不经常",
        },
        {
          value: "1",
          label: "可能性小",
        },
        {
          value: "0.5",
          label: "很不可能",
        },
        {
          value: "0.1",
          label: "极不可能",
        },
      ],
      llist1: [
        {
          value: "10",
          label: "连续暴露",
        },
        {
          value: "6",
          label: "每天工作时间内暴露",
        },
        {
          value: "3",
          label: "每周一次或偶然暴露",
        },
        {
          value: "2",
          label: "每月暴露一次",
        },
        {
          value: "1",
          label: "每年几次暴露",
        },
        {
          value: "0.5",
          label: "非常罕见暴露",
        },
      ],
      llist2: [
        {
          value: "100",
          label: "10人以上死亡",
        },
        {
          value: "40",
          label: "3~9人死亡",
        },
        {
          value: "15",
          label: "1~2人死亡",
        },
        {
          value: "7",
          label: "严重",
        },
        {
          value: "3",
          label: "重大，伤残",
        },
        {
          value: "1",
          label: "引人注意",
        },
      ],
      llist3: [
        {
          value: "A",
          label: "很可能",
        },
        {
          value: "B",
          label: "可能但不经常",
        },
        {
          value: "C",
          label: "可能性小完全意外",
        },
        {
          value: "D",
          label: "很不可能可以设想",
        },
        {
          value: "E",
          label: "极不可能",
        },
      ],
      llist4: [
        {
          value: "Ⅰ",
          label: "灾难，可能发生重特大事故",
        },
        {
          value: "Ⅱ",
          label: "严重，可能发生较大事故",
        },
        {
          value: "Ⅲ",
          label: "轻度，可能发生一般事故",
        },
        {
          value: "Ⅳ",
          label: "轻微，可能发生人员轻伤事故",
        },
      ],
      hazard: "",
      projectrola: "",
      controls: "",
      manager: "",
      personage: "",
      riskInfo: {},
      //XIUGAISHIYONG
      xiugaifengxian: false,
      data_xialakuang_two: "",
      data_xialakuang_one: "",
      table_all_addl: false,
      table_data_list: [],
      hd: 0,
      table_page: 1,
      table_size: 5,
      table_pages: 1,
      table_sizes: 5,
      currentPage4: 1,
      table_count: 0,
      tableDatas: [],
      table_tk: false,
      table_tks: false,
      addDialogVisible: false,
      tableData: [],
      tableData1: [],
      tableData1s: [],
      nowPage: 1,
      moreShowBoolen: false, //控制加载更多
      data_two_xialakuang_list: [
        {
          value: "选项1",
          label: "施工用电设备设施",
        },
        {
          value: "选项2",
          label: "模板支架",
        },
        {
          value: "选项3",
          label: "塔式起重机设备设施",
        },
        {
          value: "选项4",
          label: "部分施工机具设备设施",
        },
        {
          value: "选项5",
          label: "扣件式钢管脚手架",
        },
        {
          value: "选项6",
          label: "脚手架工程",
        },
        {
          value: "选项7",
          label: "临时设施",
        },
        {
          value: "选项8",
          label: "施工升降机",
        },
        {
          value: "选项9",
          label: "外挂架",
        },
        {
          value: "选项10",
          label: "物料提升机",
        },
        {
          value: "选项11",
          label: "高处作业防护设施",
        },
      ],
      data_xialakuang_list: [
        {
          value: "选项1",
          label: "基坑工程",
        },
        {
          value: "选项2",
          label: "模板工程",
        },
        {
          value: "选项3",
          label: "脚手架工程",
        },
        {
          value: "选项4",
          label: "砌体工程",
        },
        {
          value: "选项5",
          label: "塔式起重机安拆及使用作业活动",
        },
        {
          value: "选项6",
          label: "动火作业",
        },
        {
          value: "选项7",
          label: "施工升降机使用",
        },
        {
          value: "选项8",
          label: "钢筋工程",
        },
        {
          value: "选项9",
          label: "钢结构工程",
        },
        {
          value: "选项10",
          label: "高空作业",
        },
        {
          value: "选项11",
          label: "给排水及采暖工程",
        },
        {
          value: "选项12",
          label: "混凝土工程",
        },
        {
          value: "选项13",
          label: "起重设备使用",
        },
        {
          value: "选项14",
          label: "施工升降机安拆",
        },
        {
          value: "选项15",
          label: "外挂架作业",
        },
        {
          value: "选项16",
          label: "物料提升机安拆",
        },
        {
          value: "选项17",
          label: "物料提升机使用",
        },
        {
          value: "选项18",
          label: "装配式建筑工程",
        },
      ],
      addFormData: {
        corporation: "",
        engineering: "",
        workName: "",
        pid: "",
      },
      corporationOptions: [],

      Risklevel: [
        {
          value: "低风险",
          label: "低风险",
        },
        {
          value: "一般风险",
          label: "一般风险",
        },
        {
          value: "较大风险",
          label: "较大风险",
        },
        {
          value: "重大风险",
          label: "重大风险",
        },
      ],
      flag: true, //判断是添加选择框还是修改选择框
      dialogTitle: "添加风险",
      riskname: "",
      risktype: "",
      riskrank: "",
      total: 0,
      page: 1,
      pagesize: 5,
      engineeringOptions: [], //工程下拉框
      workNameOptions: [], //作业活动内容
      workOptions: [], //作业活动内容
      controlsdialogVisible: false, //控制分级管控对话框的显示与隐藏
      controlsFormData: {
        rid: null,
        functionary1: "",
        functionary2: "",
        functionary3: "",
        functionary4: "",
      }, //分级管控表单
      controlsOptions: [
        {
          value: "项目级",
          label: "项目级",
        },
        {
          value: "企业级",
          label: "企业级",
        },
        {
          value: "班组级",
          label: "班组级",
        },
      ],
      screeningdialogVisible: false, //隐患排查对话框的显示与隐藏
      duoxuqan: [],
      checkList: [], //隐患排查多选数组
      screeningFormData: {
        bzxj: 0,
        bzjjb: 0,
        mbn: 0,
        mz: 0,
        my: 0,
      }, //隐患排查表单
      adddFormDataRules: {
        engineering: [
          { required: true, message: "请选择所属工程", trigger: "blur" },
        ],
        workName: [
          { required: true, message: "请选择作业活动名称", trigger: "blur" },
        ],
        pid: [
          { required: true, message: "请选择作业活动内容", trigger: "blur" },
        ],
      },
      riskInfo: {},
      activeName: 1,
      pjtid:''
    };
  },
  created() {

  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.personnelInformation()
   
    },
  },
  mounted() {
    //通过项目id获取人员信息
    this.personnelInformation()
    // 获取所有的项目
    // this.getProject();
    // 获取某个项目风险
  },
  methods: {
    //通过项目id获取人员信息
    async personnelInformation() {
      let msg = {
        unique_identification: localStorage.getItem("uid"),

      }
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project') 
        }else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project') 
        }
      }
      const res = await personnelInformation(msg)
      console.log(res, '通过项目id获取人员信息');
      if (res.data.data.length > 0) {
        this.data_list = res.data.data
        this.activeNames = res.data.data[0].id
      }
      this.getRisk();
    },

    handleClick(tab, event) {
      console.log(this.activeName);
      this.getRisk();
    },
    //自定义添加风险下拉框判断是设备或作业
    dropDown(e) {
      if (e == 1) {
        this.show = true;
        this.show1 = false;
        this.form.sb_hd = 1;
      } else {
        this.show1 = true;
        this.show = false;
        this.form.sb_hd = 2;
      }
    },
    //自定义添加风险多选框
    multiSelect(e) {
      //  console.log(e,'eee')
      e.forEach((item) => {
        //  console.log(item,'每一项')
        if (item == "班中巡查") {
          this.bzxc = 1;
        } else if (item == "班中交接班") {
          this.bzjj = 1;
        } else if (item == "每半年/企业") {
          this.bn = 1;
        } else if (item == "每周/项目部") {
          this.z = 1;
        } else if (item == "每月/企业") {
          this.Y = 1;
        }
      });
    },
    async bounced() {
      let msg = {
        name: this.form.name,
        riskname: this.form.riskname,
        risktype: this.form.risktype,
        riskpoint: this.form.riskpoint,
        hazard: this.form.hazard,
        controls: this.form.controls,
        projectrola: this.form.projectrola,
        personage: this.form.personage,
        manager: this.form.manager,
        riskrank: this.form.riskrank,
        corporation: localStorage.getItem("corporations"),
        control: this.form.control,
        control1: this.form.control1,
        control2: this.form.control2,
        control3: this.form.control3,
        control4: this.form.control4,
        sb_hd: this.form.sb_hd,
        bzxj: this.bzxc,
        bzjjb: this.bzjj,
        mbn: this.bn,
        mz: this.z,
        my: this.Y,
        L: this.form.L,
        E: this.form.E,
        C: this.form.C,
        K: this.form.K,
        Y: this.form.Y,
      };
      const res = await TheCustom(msg);
      if (res.data.ocde == 200) {
        this.bzxc = 0;
        this.bzjj = 0;
        this.bn = 0;
        this.z = 0;
        this.Y = 0;
        this.form.name = "";
        this.form.riskname = "";
        this.form.risktype = "";
        this.form.riskpoint = "";
        this.form.hazard = "";
        this.form.controls = "";
        this.form.projectrola = "";
        this.form.personage = "";
        this.form.manager = "";
        this.form.riskrank = "";
        this.form.control = "";
        this.form.control1 = "";
        this.form.control2 = "";
        this.form.control3 = "";
        this.form.control4 = "";
        this.form.sb_hd = "";
        this.form.L = "";
        this.form.E = "";
        this.form.C = "";
        this.form.K = "";
        this.form.Y = "";
        this.dialogFormVisible = false;
        this.$message({
          type: "success",
          message: "添加风险成功",
        });
      } else {
        this.$message({
          type: "info",
          message: "添加风险失败",
        });
      }
    },
    filterTag(value, row) {
      return row.jkgc === value;
    },
    async xiugai() {
      if (this.E != undefined) {
        var dataname = this.L * this.E * this.C;
        if (dataname < 70) {
          dataname = "低风险";
        }
        if (dataname > 70 && dataname < 160) {
          dataname = "一般风险";
        }
        if (dataname > 160 && dataname < 320) {
          dataname = "较大风险";
        }
        if (dataname > 320) {
          dataname = "重大风险";
        }
      } else {
        var dataname = "";
        if (this.L == "A" && this.C == "Ⅰ") {
          dataname = "重大风险";
        }
        if (this.L == "A" && this.C == "Ⅱ") {
          dataname = "重大风险";
        }
        if (this.L == "A" && this.C == "Ⅲ") {
          dataname = "较大风险";
        }
        if (this.L == "A" && this.C == "Ⅳ") {
          dataname = "一般风险";
        }
        if (this.L == "B" && this.C == "Ⅰ") {
          dataname = "重大风险";
        }
        if (this.L == "B" && this.C == "Ⅱ") {
          dataname = "重大风险";
        }
        if (this.L == "B" && this.C == "Ⅲ") {
          dataname = "较大风险";
        }
        if (this.L == "B" && this.C == "Ⅳ") {
          dataname = "一般风险";
        }
        if (this.L == "C" && this.C == "Ⅰ") {
          dataname = "重大风险";
        }
        if (this.L == "C" && this.C == "Ⅱ") {
          dataname = "较大风险";
        }
        if (this.L == "C" && this.C == "Ⅲ") {
          dataname = "一般风险";
        }
        if (this.L == "C" && this.C == "Ⅳ") {
          dataname = "低风险";
        }
        if (this.L == "D" && this.C == "Ⅰ") {
          dataname = "较大风险";
        }
        if (this.L == "D" && this.C == "Ⅱ") {
          dataname = "一般风险";
        }
        if (this.L == "D" && this.C == "Ⅲ") {
          dataname = "一般风险";
        }
        if (this.L == "D" && this.C == "Ⅳ") {
          dataname = "低风险";
        }
        if (this.L == "E" && this.C == "Ⅰ") {
          dataname = "一般风险";
        }
        if (this.L == "E" && this.C == "Ⅱ") {
          dataname = "一般风险";
        }
        if (this.L == "E" && this.C == "Ⅲ") {
          dataname = "一般风险";
        }
        if (this.L == "E" && this.C == "Ⅳ") {
          dataname = "低风险";
        }
      }
      var msg = {
        id: this.id,
        L: this.L,
        E: this.E,
        C: this.C,
        dataname: dataname,
        hazard: this.hazard,
        projectrola: this.projectrola,
        controls: this.controls,
        manager: this.manager,
        personage: this.personage,
      };

      const res = await update_Risk_data(msg);
      console.log(msg);
      console.log(res.data.code);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "数据修改成功",
          type: "success",
        });

        this.getRisk();
        this.xiugaifengxian = false;
      }
    },
    async sousuo_name_two() {
      this.hd = 2;
      var msg = {
        pro_id: this.activeNames,
        name_type: "3",
        // page: this.table_page,
        // size: this.table_size,
        page: 1,
        size: 10000,
        hd: this.hd,
        name: this.data_xialakuang_two,
      };
      const res = await add_Data_Risk(msg);
      this.table_count = res.data.count;
      this.tableDatas = res.data.data;
      this.getRisk();
    },
    async sousuo_name() {
      this.hd = 1;
      var msg = {
        pro_id: this.activeNames,
        name_type: "3",
        // page: this.table_page,
        // size: this.table_size,
        page: 1,
        size: 10000,
        hd: this.hd,
        name: this.data_xialakuang_one,
      };
      const res = await add_Data_Risk(msg);
      this.table_count = res.data.count;
      this.tableDatas = res.data.data;
      this.getRisk();
    },
    async table_add_risk() {
      this.table_tk = false;
      console.log(this.table_data_list);
      var msg = {
        dada: this.table_data_list,
        corporation:this.activeNames,
      };
      const res = await add_table_risk(msg);
      console.log(res);
      if (res.data.code !== "200") {
        this.$message.error("添加失败");
        return false;
      }
      this.$message.success("添加成功!");
      this.table_data_list = [];
      this.getRisk();
    },
    async table_add_risks() {
      this.table_tks = false;
      console.log(this.table_data_list);
      var msg = {
        dada: this.table_data_list,
        corporation:this.activeNames,

      };
      const res = await add_table_risk(msg);
      console.log(res);
      if (res.data.code !== "200") {
        this.$message.error("添加失败");
        return false;
      }
      this.$message.success("添加成功!");
      this.table_data_list = [];
      this.getRisk();
    },
    async table_handleSizeChange(val) {
      this.table_size = val;
      this.table_all();
    },
    async table_handleCurrentChange(val) {
      this.table_page = val;
      this.table_all();
    },
    async table_handleSizeChanges(val) {
      this.table_sizes = val;
      this.table_alls();
    },
    async table_handleCurrentChanges(val) {
      this.table_pages = val;
      this.table_alls();
    },
    handleSelectionChanges(val) {
      this.table_data_list = val;
    },
    async table_all() {
      this.hd = 1;
      var msg = {
        pro_id: this.activeNames,

        name_type: "1",
        page: 1,
        size: 10000,
        // page: this.table_page,
        // size: this.table_size,
        hd: this.hd,
        name: this.data_xialakuang_one,
      };
      const res = await add_Data_Risk(msg);
      this.table_count = res.data.count;
      this.tableDatas = res.data.data;
      this.table_tk = true;
    },
    async table_alls() {
      this.hd = 2;
      var msg = {
        pro_id: this.activeNames,

        name_type: "1",
        page: 1,
        size: 10000,
        // page: this.table_pages,
        // size: this.table_sizes,
        hd: this.hd,
        name: this.data_xialakuang_two,
      };
      const res = await add_Data_Risk(msg);
      this.table_count = res.data.count;
      this.tableDatas = res.data.data;
      this.table_tks = true;
    },
    handleSelectionChange(val) {
      this.duoxuqan = val;
      console.log(this.duoxuqan, "duoxuqan");
    },
    // 提交隐患排查
    async screeningSubmit() {
      for (let i = 0; i < this.checkList.length; i++) {
        if (this.checkList[i] == "班中巡检") {
          this.screeningFormData.bzxj = 1;
        } else if (this.checkList[i] == "班中交接班") {
          this.screeningFormData.bzjjb = 1;
        } else if (this.checkList[i] == "每半年/企业") {
          this.screeningFormData.mbn = 1;
        } else if (this.checkList[i] == "每周/项目部") {
          this.screeningFormData.mz = 1;
        } else if (this.checkList[i] == "每月/企业") {
          this.screeningFormData.my = 1;
        }
      }
      console.log(this.screeningFormData);
      const res = await screeningToRisk(this.screeningFormData);
      console.log(res);
      if (res.data.code == 200) {
        this.$message.success("添加隐患排查成功!");
        this.screeningdialogVisible = false;
      }
    },
    // 打开隐患排查对话框
    openScreening(row) {
      this.screeningFormData.rid = row.id;
      this.screeningdialogVisible = true;
    },
    // 关闭添加分级管控对话框
    closeControlsdialogVisible() {
      this.$refs.controlsFormData.resetFields();
    },
    // 打开分级管控对话框
    openControls() {
      if (this.duoxuqan.length == 0) {
        this.controlsdialogVisible = false;
        this.$message.success("请选择风险点!");
      } else {
        if (this.duoxuqan.length == 1) {
          this.state = this.duoxuqan[0].riskrank;
          this.controlsdialogVisible = true;
        } else {
          this.controlsdialogVisible = true;
        }
      }
    },
    // 分级管控
    async controlSubmit() {
      console.log(this.controlsFormData);
      for (let i = 0; i < this.duoxuqan.length; i++) {
        this.controlsFormData.rid = this.duoxuqan[i].id;
        const res = await controlToRisk(this.controlsFormData);
        console.log(res);
        if (res.data.code !== "200") {
          this.$message.error("添加分级管控失败");
          return false;
        }
        this.$message.success("添加分级管控成功!");
      }


      this.controlsdialogVisible = false;
      this.getRisk();
    },
    // 作业活动名称改变
    async risknameHandleChange(id) {
      let msg = {
        zid: id,
      };
      const res = await showRiskToProject(msg);

      this.workOptions = res.data;
      this.addFormData.pid = this.workOptions[0].id;
    },
    // 所属工程改变 获取作业活动名称
    async engineeringHandleChange(id) {
      let msg = {
        zid: id,
      };
      const res = await showRiskToProject(msg);

      this.workNameOptions = res.data;
      this.addFormData.workName = this.workNameOptions[0].id;
      // 获取作业活动内容
      this.risknameHandleChange(this.addFormData.workName);
    },
    // 获取所属工程
    async getWork(id) {
      let msg = {
        zid: 0,
      };
      const res = await showRiskToProject(msg);

      this.engineeringOptions = res.data;
      this.addFormData.engineering = this.engineeringOptions[0].id;
      // 获取工程下作业活动名称
      this.engineeringHandleChange(this.addFormData.engineering);
    },

    // 一页显示条数改变
    handleSizeChange(page) {
      this.page = page;
      this.getRisk();
    },
    // 页码改变
    handleCurrentChange(pagesize) {
      this.pagesize = pagesize;
      this.getRisk();
    },
    // 添加风险对话框,修改风险对话框关闭
    closeDialog() {
      this.dialogTitle = "添加风险";
      // this.$refs.addFormData.resetFields();
      (this.addFormData = {
        corporation: "",
        riskname: "",
        risktype: "",
        riskpoint: "",
        riskranks: {
          accidentprobability: "",
          Accidentseverity: "",
          frequently: "",
        },
        riskrank: "",
        details: "",
        functionary: "",
        Screening: "",
      }),
        (this.flag = true);
    },
    // 重置条件
    reset() {
      this.riskname = "";
      this.risktype = "";
      this.riskrank = "";
      this.getRisk();
    },
    // 搜索风险
    async searchRisk() {
      this.optionsrisk.forEach((item) => {
        if (this.riskname == item.value) {
          this.riskname = item.label;
        }
      });
      this.getRisk();
    },
    // 修改风险
    async editRisk(row) {
      // this.addFormData = JSON.parse(JSON.stringify(row));
      this.xiugaifengxian = true;
      console.log(row);
      let msg = {
        id: row.id,
      };
      const res = await getThreeRiskInfo(msg);
      // console.log(res);
      this.riskInfo = res.data.data[0];
      this.id = res.data.data[0].id;
      this.riskInfo.hazard = this.riskInfo.detail.hazard;
      this.hazard = this.riskInfo.detail.hazard;
      this.riskInfo.hazard = this.riskInfo.detail.personage;
      this.personage = this.riskInfo.detail.personage;
      this.riskInfo.projectrola = this.riskInfo.detail.projectrola;
      this.projectrola = this.riskInfo.detail.projectrola;
      this.riskInfo.controls = this.riskInfo.detail.controls;
      this.controls = this.riskInfo.detail.controls;
      this.riskInfo.manager = this.riskInfo.detail.manager;
      this.manager = this.riskInfo.detail.manager;

      this.riskInfo.E = this.riskInfo.riskran.E;
      this.E = this.riskInfo.riskran.E;

      this.riskInfo.control1 = this.riskInfo.functionarys.control1;
      this.riskInfo.control2 = this.riskInfo.functionarys.control2;
      this.riskInfo.control3 = this.riskInfo.functionarys.control3;
      this.riskInfo.control4 = this.riskInfo.functionarys.control4;
      console.log(this.riskInfo.riskran.L);
      if (this.riskInfo.riskran.L != undefined) {
        this.activeName = 1;
        this.riskInfo.L = this.riskInfo.riskran.L;
        this.riskInfo.C = this.riskInfo.riskran.C;
        this.L = this.riskInfo.riskran.L;
        this.C = this.riskInfo.riskran.C;
      } else {
        this.activeName = 2;
        this.riskInfo.L = this.riskInfo.riskran.K;
        this.L = this.riskInfo.riskran.K;
        this.riskInfo.C = this.riskInfo.riskran.Y;
        this.C = this.riskInfo.riskran.Y;
      }
    },
    // 删除风险
    deleteRisk(row) {
      // console.log(row);
      let msg = {
        id: row.id,
      };

      this.$confirm("是否删除此风险", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteRiskToProject(msg);
          console.log(res);
          if (res.data.code == 200) {
            this.getRisk();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 控制表格风险级别文字颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);
      // console.log(row.column);
      if (row.column.label == "风险等级" && row.row.riskrank == "低风险") {
        return "color:#0070c0";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "一般风险"
      ) {
        return "color:rgb(227 227 36)";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "较大风险"
      ) {
        return "color:#fd5c0c";
      } else if (
        row.column.label == "风险等级" &&
        row.row.riskrank == "重大风险"
      ) {
        return "color:#d8090f";
      }
    },

    // 获取风险
    async getRisk() {
      let msg = {
        riskname: this.riskname,
        risktype: this.risktype,
        riskrank: this.riskrank,
        corporation: this.activeNames,
        // size: this.pagesize,
        // page: this.page,
        size: 10000,
        page: 1,
      };
      const res = await getRiskToProject(msg);
      this.tableData1 = res.data.data;
      this.tableData1s = res.data.con
      // this.total = res.data.count;
      if (res.data.data.length > 0) {
        if (res.data.data <= 15) { // 10条数据一页
          this.tableData = tableData1
          // this.moreShowBoolen = false
        } else {
          this.tableData = res.data.data.slice(0, 15)
          // this.moreShowBoolen = tr/ue
        }
      }else{
        this.tableData = res.data.data;
      }
      // this.tableData = res.data.data;
      console.log(this.tableData, '00');
      // this.con = res.data.con;
    },
    load() { // 滑动查询更多
      this.tableData = this.tableData.concat(this.tableData1.slice(this.nowPage * 15, (this.nowPage + 1) * 15))
      this.nowPage++
    },

    // 获取项目
    async getProject() {
      let msg = {
        //   name:this.name,
        // corporation:this.corporation
        unique_identification: localStorage.getItem("uid"),
        name: "",
        phase: "",
        page: this.page,
        size: this.pagesize,
      };
      const res = await getProjectInfo(msg);
      console.log(res);

      for (let i = 0; i < res.data.data.length; i++) {
        let obj = {};
        obj.value = res.data.data[i].id;
        obj.label = res.data.data[i].name;
        this.corporationOptions.push(obj);
      }
      // console.log(this.corporationOptions);
    },
    // 打开添加风险对话框
    async openDialog() {
      this.addDialogVisible = true;
      this.addFormData.corporation = this.corporationOptions[0].value;
      // 获取所属工程
      this.getWork();
    },
    // 添加风险到风险库
    async addRisk() {
      if (this.flag) {
        for (let i = 0; i < this.workNameOptions.length; i++) {
          if (this.addFormData.workName == this.workNameOptions[i].id) {
            this.addFormData.riskname = this.workNameOptions[i].partial;
          }
        }
        for (let j = 0; j < this.workOptions.length; j++) {
          if (this.addFormData.pid == this.workOptions[j].id) {
            this.addFormData.risktype = this.workOptions[j].partial;
          }
        }
        const res = await addRiskToProject(this.addFormData);
        if (res.data.code == 200) {
          this.addDialogVisible = false;
          this.getRisk();
          this.$message({
            type: "success",
            message: "添加成功",
          });
        }
      } else {
        for (let i = 0; i < this.workNameOptions.length; i++) {
          if (this.addFormData.workName == this.workNameOptions[i].id) {
            this.addFormData.riskname = this.workNameOptions[i].partial;
          }
        }
        for (let j = 0; j < this.workOptions.length; j++) {
          if (this.addFormData.pid == this.workOptions[j].id) {
            this.addFormData.risktype = this.workOptions[j].partial;
          }
        }
        const res = await editRiskToProject(this.addFormData);
        console.log(res);
        this.addDialogVisible = false;
        this.getRisk();
      }
    },
    // 批量删除
    deleteBatches() {
      this.outerVisible = true
    },
    handleDelete(row) {
      const index = this.duoxuqan.indexOf(row); // 获取当前行在表格数据中的索引
      if (index !== -1) {
        this.duoxuqan.splice(index, 1); // 从表格数据中删除当前行
      }
    },
    async confirmdeletion() {
      const idArr = this.duoxuqan.map(item => item.id)
      const str = idArr.join(',')
      console.log(str);
      let msg = {
        id_list: str
      }
      await batchDeletionRisk(msg)
      this.outerVisible = false,
        this.innerVisible = false
      this.getRisk()

    }
  },
};
</script>
<style lang="less">
/deep/ .el-table td.is-center,
.el-table th.is-center {
  text-align: left !important;
}

.bbb {
  width: 100%;
  display: flex;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.container {
  width: 100%;
}

.main {
  margin-top: 20px;
}

.search {
  .el-input {
    width: 60%;
  }
}

.addForm {
  .el-input {
    width: 65%;
  }
}

.controlsDialog {
  .el-input {
    width: 52%;

  }
}

.goBackSecond {
  display: flex;
  justify-content: flex-end;
}

.basicInfo {
  width: 100%;
  height: 200px;
  padding-top: 20px;
  padding-bottom: 40px;
  // background-color: pink;

  .basicBody {
    width: 100%;
    height: 160px;
    color: #888;
    padding-left: 15px;

    // background-color: pink;
    .basicProject {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        flex: 1;

        span {
          color: #333;
          padding-left: 10px;
        }
      }

      // background-color: skyblue;
    }
  }
}

// 公共样式类 标题
.basicTitle {
  width: 100%;
  height: 40px;
  background-color: #4c7cfc;
  letter-spacing: 2px;
  border-radius: 4px 4px 0 0;
  line-height: 40px;
  padding-left: 15px;

  span {
    color: #fff;
    font-size: 18px;
    // letter-spacing: 2px;
  }
}

.riskEvaluation {
  width: 100%;
  height: 300px;

  // background-color: pink;
  .evaluationBody {
    padding-left: 15px;

    div {
      width: 100%;
      height: 35px;
      color: #888;
      line-height: 40px;

      span {
        color: #333;
        padding-left: 10px;
      }
    }
  }
}

.severity {
  width: 100%;
  height: 100px;

  // background-color: pink;
  .severityTitle {
    background-color: #acb4cc;
    display: flex;
    align-items: center;

    div {
      flex: 1;
    }
  }

  .severityBody {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;

    div {
      flex: 1;
      height: 100%;
      line-height: 60px;
      padding-left: 10px;
    }
  }
}
</style>